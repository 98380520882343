import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { slugify } from "../utils/utilityFunctions"
import Layout from "../components/layout"
import ModalVideo from "react-modal-video"
import BackgroundImage from "gatsby-background-image"

const BlogDetails = ({ data, pageContext }) => {
  const {
    title,
    image,
    tags,
    category,
    author,
    format,
    videoId,
    videoLocation,
    videoButtonText,
  } = data.markdownRemark.frontmatter
  const imageSrc = image.childImageSharp
  const { html } = data.markdownRemark

  const baseUrl = "https://linkedin.com/"
  const [isOpen, setOpen] = useState(false)

  const assetManagement = type => {
    if (type === "image") {
      return <Img fluid={imageSrc.fluid} alt={title} />
    }
    if (type === "video") {
      return (
        <BackgroundImage fluid={imageSrc.fluid}>
          <div className="video-inner blog">
            <div className="inner">
              <div className="icon">
                <ModalVideo
                  channel={videoLocation}
                  autoplay
                  isOpen={isOpen}
                  videoId={videoId}
                  onClose={() => setOpen(false)}
                />
                <button className="video-button" onClick={() => setOpen(true)}>
                  <span className="rounded-circle"></span>{" "}
                  <span className="video-text">{videoButtonText}</span>
                </button>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }

    return null
  }

  return (
    <Layout>
      <div className="blog-details-wrapper pb--100 pt-5">
        <div className="container">
        <div className="row mb-5">
            <div className="col-lg-12">
              <div className="section-title">
                <div className="breadcrumbs-area">
                  <ul className="breadcrumbs">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="separator">
                      <span></span>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li className="separator">
                      <span></span>
                    </li>
                    <li>
                      <Link to={`/category/${slugify(category)}`}>{category}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="post-single-title">
                <h1 className="post-title">{title}</h1>
                <h2 className="muted small">by {author.name}</h2>
              </div>
              <div className="post-image">{assetManagement(format)}</div>
              <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <div className="tag-list d-flex align-items-center">
                <span>Tags:</span>
                <div className="tags-cloud">
                  {tags.map(tag => (
                    <a key={tag} href={`/tag/${slugify(tag)}`}>
                      {tag}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-contact-form">
                <div className="social-share-inner text-center pt--50">
                  <h3>Share This Post</h3>
                  <ul className="social-share-links liststyle d-flex justify-content-center">
                    <li>
                      <a
                        className="facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://www.facebook.com/sharer.php?u=" +
                          baseUrl +
                          pageContext.slug
                        }
                      >
                        <span>facebook</span>
                      </a>
                    </li>

                    <li>
                      <a
                        className="twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://www.twitter.com/share?url=" +
                          baseUrl +
                          pageContext.slug +
                          "&text=" +
                          title +
                          "&via" +
                          "twitterHandle"
                        }
                      >
                        <span>Twitter</span>
                      </a>
                    </li>

                    <li>
                      <a
                        className="google"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://plus.google.com/share?url=" +
                          baseUrl +
                          pageContext.slug
                        }
                      >
                        <span>Google</span>
                      </a>
                    </li>

                    <li>
                      <a
                        className="linkedin"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${baseUrl}${pageContext.slug}`}
                      >
                        <span>LinkedIn</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const blogDetailsData = graphql`
  query blogDetailsQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        author {
          name
        }
        category
        title
        date(formatString: "MMM Do, YYYY")
        format
        tags
        videoId
        videoLocation
        videoButtonText
        image {
          childImageSharp {
            fluid(quality: 100, maxHeight: 350, maxWidth: 510) {
              ...GatsbyImageSharpFluid_withWebp
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
  }
`

export default BlogDetails
